@import 'variables/variables.scss';
@import 'form-chat.scss';
@import 'list-message.scss';

.chat_component {
  height: 100%;

  .ant-spin-nested-loading {
    width: 100%;
    overflow: hidden;
    flex: 1 1 auto;
  }

  .col {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-card-head-wrapper {
    height: 100%;
  }
  .ant-card-head-title {
    display: flex;
    align-items: center;
    height: 100%;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  .list_incident {
    overflow: auto;
    overflow-x: hidden;
    height: 90%;
    max-height: 100%;
    border-radius: 2 * $basic-space !important;
    border: 0.25 * $basic-space solid #e1e1e1;
    box-shadow: 0px 4px 8px #f3f3f3;
    .ant-spin-nested-loading {
      overflow: auto;
      overflow-x: hidden;
    }
    .ant-scroll-number-only-unit {
      color: #fff;
    }
    & {
      .ant-card-body {
        padding: 0;
        height: 100%;
      }
      .list_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: none;
        outline: none;
        box-sizing: border-box;
        border-radius: 2 * $basic-space 2 * $basic-space 0 0;
        cursor: pointer;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-large;
        line-height: 1.6;
        color: #404040;
        & {
          .list_message {
            display: flex;
            justify-content: unset;
            width: 100%;
            height: 100%;
            border-bottom: none;
            padding: 5 * $basic-space !important;
          }
          .list_message:first-child {
            border-radius: 2 * $basic-space 2 * $basic-space 0 0;
          }
          .list_message:last-child px {
            border-radius: 0 0 2 * $basic-space 2 * $basic-space;
          }
          .image {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #21c0f6;
            position: relative;
          }
          .svg-image {
            position: absolute;
            left: 53%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 23px;
          }
          .info_chat {
            flex: 1;
            height: 100%;
            margin-left: 3 * $basic-space;
            width: 66%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .ant-space-item {
              width: 100%;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .top-title {
              font-size: $font-size-large;
              font-weight: bold;
              line-height: 1.5;
              font-family: $font-family;
              color: #404040;
            }
            .bottom-title {
              font-size: $font-size-large;
              line-height: 1.42;
              font-family: $font-family;
            }
          }
          .ant-typography {
            font-size: $font-size-large;
            line-height: 1.42;
            font-family: $font-family;

            margin: 0;
          }
          .info_time {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            margin-left: 0.75 * $basic-space;
          }
          .text_time {
            font-size: $font-size-small;
            line-height: 1.5;
            text-align: right;
            color: #a8a8a8;
            padding-bottom: 6.25 * $basic-space;
            font-family: $font-family;
          }
          .hide_text {
            font-family: $font-family;
            font-size: $font-size-normal;
            line-height: 1.5;
            text-align: right;
            text-decoration-line: underline;
            color: #777777;
          }
        }
        &::-webkit-scrollbar {
          width: $basic-space;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $primary-color-button;
          background-clip: content-box;
        }
      }
    }
  }

  .card_info {
    flex: 1;
    border-radius: $basic-space;
    box-shadow: 0px 4px 8px #f3f3f3;
    padding-left: 3 * $basic-space;
    padding-bottom: 2.5 * $basic-space;
    padding-top: 2 * $basic-space;
    padding-right: 2.5 * $basic-space;
    margin-top: 3 * $basic-space;
    border-radius: 2 * $basic-space;
    & {
      .ant-card-body {
        padding: 0;
      }
    }
  }
  .right {
    border-radius: 2 * $basic-space !important;
    padding: 0;
    .ant-spin-nested-loading {
      .ant-spin-container {
        height: 100%;
        overflow: hidden;
      }
    }
  }
  .chat_content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 2 * $basic-space 2 * $basic-space 0 0 !important;
    box-shadow: 0px 4px 8px #f3f3f3;
    & {
      .ant-card-body {
        padding: 0;
        height: 95%;
        overflow: auto;
        max-height: 100%;
        @media screen and (min-height: 900px) and (max-height: 1000px) {
          height: 93%;
        }
        @media screen and (min-height: 800px) and (max-height: 900px) {
          height: 92%;
        }
        @media screen and (min-height: 700px) and (max-height: 800px) {
          height: 91%;
        }
        @media screen and (min-height: 600px) and (max-height: 700px) {
          height: 90%;
        }
      }
      .ant-card-meta {
        height: 100%;
      }
      .ant-card-meta-detail {
        height: 100%;
      }
      .ant-card-meta-description {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .ant-card-meta:last-child {
        border-bottom: none;
      }
      .ant-card-head {
        height: 48px;
        z-index: 10;
        padding-right: 0 4 * $basic-space;
        border-radius: 2 * $basic-space 2 * $basic-space 0 0;
        font-weight: bold;
        font-size: $font-size-x-large;
        line-height: 1.5;
        background: $primary-color;
        color: #ffffff;
      }
      .ant-btn {
        margin-left: 2.5 * $basic-space;
      }

      .ant-input-affix-wrapper {
        padding-left: 5 * $basic-space;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      .ant-input {
        height: 48px;
        box-sizing: border-box;
        border-radius: $basic-space;
        font-family: $font-family;
        border: 0.375 * $basic-space solid #e1e1e1;
        font-size: $font-size-large;
        line-height: 1.6;
        cursor: pointer;
        padding-top: 0;
      }
    }
  }

  .ant-image {
    cursor: pointer;
  }

  .col_notfound {
    box-shadow: 4px 8px 20px #f4f4f4;
    border-radius: $basic-space;
    display: flex !important;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & {
      .ant-image {
        max-width: 140px;
      }
      .notfound_text {
        font-size: $font-size-bigger;
        line-height: 1.5;
        display: flex;
        align-items: center;
        text-align: center;
        color: #a8a8a8;
        max-width: 246px;
        padding: 0 5 * $basic-space;
      }
    }
  }

  .space-note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .ant-space-item {
      position: relative;
      width: 100%;
    }
    .text-note {
      font-size: $font-size-normal;
      line-height: 1.5;
      padding-left: 12px;
      color: #404040;
    }
    .text-note::before {
      content: '.';
      font-size: 2rem;
      position: absolute;
      top: -5 * $basic-space;
      left: 0;
    }
  }
  .scrollable {
    padding: 4 * $basic-space;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  .card_sendmessage {
    border-radius: 0 0 2 * $basic-space 2 * $basic-space;
    border-top: 0.25 * $basic-space solid #f0f0f0;

    .ant-card-body {
      padding: 0;
    }
  }
}
.modal-hideincident {
  border-radius: 2 * $basic-space;
  top: 35% !important;

  .content-modal {
    display: flex;
    width: 100%;
    margin-top: 3.75 * $basic-space;
    padding: 0 4.5 * $basic-space;
    .cancel {
      margin-right: 3.75 * $basic-space;
      color: #21c0f6;
      border: 0.375 * $basic-space solid #21c0f6;
      border-radius: $basic-space;
    }
  }
  .text_modal {
    width: 100%;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
    color: $primary-color-title;
    padding: 0 5 * $basic-space;
    text-align: center;
  }

  .button_modal {
    flex-basis: 50%;
    height: 48px;
    width: 100%;
    border-radius: $basic-space;
    font-size: $font-size-bigger;
    line-height: 1.25;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
  }
}
