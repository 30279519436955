@import 'variables/variables.scss';

.header_component {
  height: 60px !important;
  width: 100%;
  box-shadow: 0px $basic-space 2 * $basic-space #f3f3f3;
  background-color: $primary-default-color !important ;
  padding-left: 4 * $basic-space !important;
  padding-right: 0 !important;
  display: flex;
  align-items: center;
  line-height: 50px !important;
  .search {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .search-input {
      max-width: 325px;
    }
  }
  .container-org {
    padding-left: 2rem;
  }
  .space-orgzation {
    .ant-space-item {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 650px;
    }
  }
  .ant-menu-title-content {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ant-input-group {
    height: 40px;
  }
  .ant-input-affix-wrapper {
    border: 0.25 * $basic-space solid #e1e1e1 !important;
    background: $primary;
    height: 40px;
    max-height: 40px;
    border-radius: $basic-space !important;
  }
  .ant-input {
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
    color: #a8a8a8;
    border-radius: $basic-space;
    background: $primary;
  }
  .ant-input-search-button {
    display: none !important;
  }
  .ant-input-group-addon {
    border-radius: $basic-space !important;
    background-color: #d9d9d9 !important;
    display: none !important;

    button {
      display: none;
    }
  }

  .ant-btn:active {
    color: none !important;
    border-color: none !important;
    background: $primary-default-color;
  }
  .ant-btn:hover {
    color: none !important;
    border-color: none !important;
    background: $primary-default-color;
  }
  .ant-btn:focus {
    color: none !important;
    border-color: none !important;
    background: $primary-default-color;
  }
  .logo {
    font-size: $font-size-x-large;
    color: $primary-color-title;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo_image {
      margin-right: 1.25 * $basic-space;
      width: 44px;
      height: 31px;
      cursor: pointer;
    }
    .logo_title {
      color: $primary-color-title;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    border: none !important;
    height: 60px;
    justify-content: flex-end;
    .menu_item {
      display: flex;
      justify-content: center;
      height: 60px;
      align-items: center;
    }
    .menu_item::after {
      border-bottom: none !important;
    }
  }
  .header_title {
    flex: 1;
    display: flex;
    justify-content: center;
    padding-top: 2.5 * $basic-space;
    .title {
      color: $primary-color-button;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.5;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .btn {
    height: 40px;
    color: $primary-color-title;
    font-weight: 500;
    padding: 0px 2.5 * $basic-space;
    font-size: $font-size-large;
    line-height: 1.6;
    display: flex;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    background: $primary;
    color: #404040 !important;
    border: 0.375 * $basic-space solid #e1e1e1 !important;
    box-sizing: border-box;
    border-radius: $basic-space;
  }

  .btn_changeorzgation {
    height: 40px;
    color: $primary-color-title;
    font-weight: 500;
    padding: 0px 2.5 * $basic-space;
    font-size: $font-size-large;
    line-height: 1.6;

    display: flex;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;

    color: #404040 !important;
    border: 0.375 * $basic-space solid #e1e1e1 !important;
    box-sizing: border-box;
    border-radius: $basic-space;
    background: $primary;
  }
  .btn_logout {
    height: 40px;
    font-weight: 500;
    padding: 0px 2.5 * $basic-space;
    border-radius: $basic-space;
    font-size: $font-size-large;
    line-height: 1.6;
    display: flex;
    align-items: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    border-color: $primary-color-button;
    color: $primary-color-button;
  }
  .lang {
    margin-right: 4 * $basic-space;
  }
}
.menu_multi {
  width: 100%;
  height: 100%;
  min-width: 332px;
  max-height: 244px;
  top: 1.5 * $basic-space;
  background: $primary-default-color;
  padding: 0 !important;
  border: 0.25 * $basic-space solid #e1e1e1;
  border-radius: $basic-space !important;

  & {
    li {
      padding-left: 7 * $basic-space;
      height: 60px;
      margin: 0 !important;
      box-sizing: border-box;
      border-bottom: 0.25 * $basic-space solid #e1e1e1;
      font-size: $font-size-large;
      line-height: 1.6;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      display: flex;
      align-items: center;
    }
    li:last-child {
      border-bottom: none;
    }
  }
}
