@import 'variables/variables.scss';

.ant-popover-content {
  position: absolute;
  right: 70px;
  bottom: -50px;

  .ant-popover-placement-top .ant-popover-arrow {
    right: 5px;
  }
}
.chat_component {
  ::-webkit-scrollbar {
    width: $basic-space !important;
    height: $basic-space;
  }
  .form_chat {
    display: flex;
    align-items: center;
    padding-left: 6.25 * $basic-space;
    padding-top: 4 * $basic-space;
    padding-right: 6.25 * $basic-space;
    padding-bottom: 4 * $basic-space;
    .preview_container {
      background: #e1e1e1;
      max-height: 64px;
      border-radius: 2 * $basic-space;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 3 * $basic-space 2 * $basic-space;
      margin-right: 5.25 * $basic-space;
      .article_file {
        display: flex;
      }
      .file-name {
        display: flex;
        font-family: Noto Sans CJK JP;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-large;
        line-height: 1.6;
        color: #000000;
        margin-left: 2 * $basic-space;
        .name {
          display: inline-block;
          max-width: 70px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-image {
        position: unset;
      }
      .img-remove {
        position: absolute;
        top: -2.5 * $basic-space;
        right: -2.5 * $basic-space;
        width: 6 * $basic-space;
        height: 6 * $basic-space;
      }
    }

    .ant-spin-nested-loading {
      overflow: unset;
      flex: unset;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .chat_area {
      position: relative;
      row-gap: 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 0.25 * $basic-space solid #3eb5e9;
      box-sizing: border-box;
      border-radius: $basic-space;
      height: 100%;
      margin-bottom: 0 !important;

      @media screen and (max-height: 800px) {
        max-height: 115px;
      }
      & {
        .ant-form-item-control,
        .ant-form-item-control-input,
        .ant-form-item-control-input-content {
          height: 100%;
        }
        .ant-row .ant-form-item {
          width: 100%;
        }

        .ant-form-item-control-input-content {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
        }
        .item-area-without-message {
          margin-bottom: 0;
          width: 100%;
          .input-area-without-message {
            border: none !important;
            outline: none;
            width: 100%;
            font-size: $font-size-large;
            font-family: $font-family !important;
            line-height: 1.6;
            color: #404040;
            height: initial !important;
          }
        }
        .item-area {
          margin-bottom: 0;

          margin-top: 2.5 * $basic-space;

          width: 100%;
          .input-area {
            border: none !important;
            outline: none;
            width: 100%;
            font-size: $font-size-large;
            font-family: $font-family !important;
            line-height: 1.6;
            color: #404040;
          }
        }
        .ant-input {
          font-size: $font-size-large;
          line-height: 1.6;
          display: flex;
          align-items: center;
          color: #404040;
          padding-top: 2.25 * $basic-space;
          padding-left: 4 * $basic-space;
          padding-right: 5.5 * $basic-space;
        }
        .footer_icon {
          padding: 2 * $basic-space;
        }
      }
    }
    .upload-item {
      .ant-upload.ant-upload-select-picture-card:hover {
        border: none;
      }
      display: flex;
      .upload-icon {
        margin-top: 1.5 * $basic-space;
        cursor: pointer;
      }
      .ant-upload {
        border-radius: $basic-space;
      }
      .ant-upload-list-text {
        display: flex;
        transition: border-color 0.3s;
      }
    }
    .footer_button {
      width: 100%;
      .ant-form-item-control-input-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .button-send {
        margin-top: 2.5 * $basic-space;
        width: 100%;
        max-width: 111px;
        background: #fea5c9;
        border: 1px solid #fea5c9;
        height: 48px;
        border-radius: $basic-space;
        color: #ffffff;
        font-weight: normal;
        font-size: $font-size-bigger;
        line-height: 1.25;
        @media screen and (max-height: 768px) {
          height: 40px;
        }
        &:active {
          border-color: unset;
        }
        &:hover {
          border-color: unset;
        }
        &:focus {
          border-color: unset;
        }
      }
    }
  }
  .normal {
    .chat_area {
      max-height: 140px;
    }

    .ant-upload-select-picture-card {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $basic-space !important;
      min-height: 6 * $basic-space;
      max-height: 6 * $basic-space;
      min-width: 6 * $basic-space;
      max-width: 6 * $basic-space;
      cursor: pointer;
      border: none !important;
      background-color: unset !important;
      margin-bottom: 0;
    }
  }
  .upload_file {
    .footer_icon {
      @media screen and (max-height: 700px) {
        padding-bottom: 0;
        padding-top: 3.75 * $basic-space;
      }
    }
    .space-buttonupload {
      width: 100%;
      .ant-space-item {
        width: 100%;
      }
      .add-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: #e0e0e0;
        border-radius: $basic-space;
        height: 12 * $basic-space;
        min-width: 16 * $basic-space;
        max-width: 16 * $basic-space;
        .ant-image {
          position: unset;
        }
        .ant-image-img {
          max-height: 64px;
          border-radius: $basic-space;
        }
        .ant-image-img:last-child {
          margin-right: 0;
        }
      }
    }

    .ant-upload-list-picture-card {
      overflow: auto;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      padding-top: 6.25 * $basic-space;
      margin-bottom: $basic-space;
      &::-webkit-scrollbar {
        width: $basic-space !important;
        height: $basic-space;
      }
    }
    .ant-upload {
      display: flex !important;
      align-items: center;
      justify-content: center;
      background: #e0e0e0;
    }
    .ant-upload-select {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $basic-space !important;
      min-height: 64px;
      max-height: 64px;
      min-width: 64px;
      max-width: 64px;
      cursor: pointer;
    }
    .ant-upload-list-picture-card-container {
      margin-bottom: $basic-space;
      width: unset;
      height: 100%;
      max-height: 64px;
      display: flex;
      align-items: center;
    }
    .chat_area {
      height: 80%;
    }
    .item-area {
      width: 100%;
      margin-top: 2.5 * $basic-space;
    }
    .emoji_upload {
      padding-bottom: 2 * $basic-space;
      padding-left: 4 * $basic-space;
      padding-right: 5.5 * $basic-space;
    }

    .upload_item {
      min-height: 189px;
      .ant-upload.ant-upload-select-picture-card {
        transition: none;
      }
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        position: relative;
        justify-content: space-around;
        .ant-space {
          max-height: 64px;
        }
      }
    }
  }
  .upload-message {
    cursor: pointer;
    width: 100%;
    min-height: 6 * $basic-space;
    display: flex;
    align-items: center;
    border-radius: $basic-space;
  }
  .file {
    padding: 2.5 * $basic-space;
    margin-top: 1.5 * $basic-space;
    overflow-y: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      width: $basic-space !important;
      height: $basic-space;
    }
  }
}

.failed {
  .ant-upload-list-picture-card-container {
    display: none;
  }
}
.emoji {
  height: 48px;
  display: flex;
  align-items: center;
}
