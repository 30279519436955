@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$font-family: Noto Sans CJK JP;
$primary: #f9f9f9;
$primary-background: #e5e5e5;
$primary-color: #21c0f6;
$primary-color-text: #c6c6c8;
$primary-color-title: #404040;
$primary-color-logo: #002e5c;
$primary-color-button: #21c0f6;
$primary-default-color: #fff;

$content-width: 1366px;
$basic-space: 4px;

$font-size-base-px: 14px;

$font-size-small: 1rem * (10px / $font-size-base-px); //10px
$font-size-normal: 1rem * (12px / $font-size-base-px); //12px;
$font-size-large: 1rem; //14px;
$font-size-big: 1rem * (15px / $font-size-base-px); //15px;
$font-size-bigger: 1rem * (16px / $font-size-base-px); //16px;
$font-size-x-large: 1rem * (18px / $font-size-base-px); //18px;
$font-size-x-big: 1rem * (20px / $font-size-base-px); //20px;
$font-size-xx-large: 1rem * (24px / $font-size-base-px); //24px;
$font-size-none: 0rem;
