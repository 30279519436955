@import 'variables/variables.scss';

.detail_container {
  height: 100%;
  overflow: hidden;
  border-radius: 2 * $basic-space;
  flex-flow: unset !important;
  flex-direction: column !important;

  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: unset;
    border-color: unset;
    border: 0.375 * $basic-space solid #21c0f6;
    box-sizing: border-box;
    margin-top: 3 * $basic-space;
    max-width: 343px;
    border-radius: $basic-space;
    margin-bottom: 4 * $basic-space;

    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
  }
  .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 10px 0;
    .ant-space-item {
      display: flex;
    }
  }
  .col {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 0.25 * $basic-space solid #e1e1e1;
    border-radius: 2 * $basic-space !important;
    background-color: #fff;
  }
  .anticon {
    color: $primary-color-button !important;
  }
  .card_article {
    border-radius: 2 * $basic-space 2 * $basic-space 0 0 !important;
    height: 100%;

    border: none !important;
    & {
      .ant-card-body,
      .ant-card-meta,
      .ant-card-meta-detail,
      .ant-card-meta-description,
      .bottom_image,
      .iframe {
        height: 100%;
      }
      .ant-card-body {
        padding: 0;
      }
      .ant-card-meta {
        margin: 0;
        border-bottom: 0.25 * $basic-space solid #e1e1e1;
        padding-left: 6.25 * $basic-space;
        padding-top: 5 * $basic-space;
        padding-right: 6 * $basic-space;
      }
      .ant-card-meta:last-child {
        border-bottom: none;
      }
      .ant-card-head {
        border-radius: 2 * $basic-space 2 * $basic-space 0 0;
        font-weight: bold;
        font-size: $font-size-x-large;
        line-height: 1.5;
        text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-card-meta-title {
        font-size: $font-size-x-large;
        line-height: 1.5;
        text-align: justify;
        font-weight: bold;
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
      }
      .ant-card-meta-description {
        display: flex;
        flex-direction: column;
      }
      .ant-card-meta-description span {
        color: #404040;
        font-size: $font-size-large;
        line-height: 1.6;
        font-family: Noto Sans CJK JP;
        font-style: normal;
        font-weight: normal;
      }
      .ant-card-meta-avatar {
        border-radius: 1.25 * $basic-space;
        padding-right: 2.5 * $basic-space;
      }
    }
  }
  .new {
    height: 20px;
    width: 31px;
    border-radius: 4px;
    background: #21c0f6;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    justify-content: center;
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: justify;
    color: #ffffff;
    padding: 2px 4px;
  }
  .card {
    display: flex;
    flex-direction: column;
    & {
      .ant-card-cover {
        height: 50%;
      }
    }
  }

  .link {
    margin-top: 2.5 * $basic-space;
  }

  .footer_button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-top: 0.25 * $basic-space solid #e1e1e1;
    padding: 0 6 * $basic-space;
    align-items: center;
    height: 12.5%;
    max-height: 68px;

    .btn_pusblish {
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      margin-right: 2.5 * $basic-space;
      width: 100%;
      max-width: 111px;
      background: #21c0f6;
      height: 42px;
      border-radius: $basic-space;
      color: #ffffff;

      &:hover {
        background: #21c0f6;
        color: #ffffff;
      }
      &:focus {
        background: #21c0f6;
        color: #ffffff;
      }
      &:active {
        background: #21c0f6;
        color: #ffffff;
      }
    }
    .btn_cancel {
      font-weight: 500;
      font-size: $font-size-bigger;
      line-height: 1.25;
      width: 100%;
      max-width: 111px;
      color: #404040;
      height: 42px;
      border-radius: $basic-space;
    }
  }
  .icon-link {
    padding-top: $basic-space;
  }

  @import 'variables/variables.scss';

  .card_bottom {
    height: 95%;
    display: flex;
    flex-direction: column;
    background: #fff;
    & {
      @media screen and (min-height: 700px) and (max-height: 800px) {
        height: 92%;
      }
    }
  }

  .card_image {
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    .time_text {
      font-size: $font-size-small !important;
      line-height: 1.5 !important;
    }
  }
  .bottom_image {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .image {
    width: 100%;
    cursor: pointer;
    height: 60%;
  }

  .name {
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 3 * $basic-space;
    font-size: $font-size-large !important;
    line-height: 1.6 !important;
  }
  .message-container {
    overflow-y: auto;
    padding-bottom: 30px;
    white-space: pre-wrap;
  }
  .message {
    margin-left: 0;
    flex: 1;
    font-size: $font-size-large !important;
    line-height: 1.6 !important;
  }

  .name-keyimage {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;

    .text_content {
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-color-title;
    }
  }
}

#myId {
  border: none;
}
