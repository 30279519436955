@import 'variables/variables.scss';
.layout_select {
  height: calc(100% - 112px);
  background-color: $primary;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .list-empty {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
  }
  .footer_select {
    text-align: center;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-large;
    line-height: 1.6;
    padding-top: 4 * $basic-space;
    padding-bottom: 3.5 * $basic-space;
    & {
      .footer_title {
        margin: 0;
        font-size: $font-size-large;
        margin-left: 2.5 * $basic-space;
        text-decoration: underline;
        cursor: pointer;
        color: $primary-color-title !important;
      }
    }
  }
  .org_name {
    font-family: Noto Sans CJK JP;

    color: #404040 !important;
    font-weight: bold !important;
    margin: 1.5rem 0 !important;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}
