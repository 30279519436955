@import 'variables/variables.scss';

.footer {
  text-align: center;
  background: #f9f9f9;
  color: #a8a8a8;
  display: flex;
  padding-top: 4 * $basic-space;
  padding-bottom: 3.5 * $basic-space;
  justify-content: center;
  align-items: center;
  font-size: $font-size-large;
  line-height: 1.6;
  font-family: Noto Sans CJK JP;
  font-size: normal;
  font-weight: normal;

  .footer-title {
    margin-left: $basic-space;
    text-decoration: underline;
    padding-top: 1.25 * $basic-space;
    font-size: $font-size-large;
    cursor: pointer;
    color: $primary-color-title !important;
  }
}
