@import 'variables/variables.scss';
.sider_component {
  background: linear-gradient(176.59deg, #3eb7e9 0%, #23a8e1 100%) !important;
  border-radius: 2 * $basic-space;
  width: 100%;
  min-width: 166px !important;
  max-width: 166px !important;

  .ant-menu-title-content {
    height: 0;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    background: #23a8e1;
  }
  .ant-menu-item-selected:first-child {
    border-radius: 2 * $basic-space 2 * $basic-space 0 0;
  }
  .ant-menu-item-selected:last-child {
    border-radius: 0 0 2 * $basic-space 2 * $basic-space;
  }
  .ant-layout-sider {
    max-width: 166px !important;
    min-width: 166px !important;
  }

  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: $primary-default-color !important;
  }
  .ant-menu-item a:hover {
    color: $primary-default-color;
  }
  .ant-menu-item:last-child .ant-menu-title-content {
    padding: 1.25 * $basic-space 0;
    font-size: $font-size-normal;
    line-height: 1.3;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: $primary-default-color;
  }

  .sider_menu .ant-badge .ant-badge-count {
    top: -2 * $basic-space;
  }
  .ant-badge .ant-scroll-number-only-unit {
    color: #404040;
  }
  .ant-menu-item:nth-child(2) .ant-menu-title-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logout {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 1.6;
    font-family: $font-family;
    color: $primary-default-color;
  }

  .ant-menu-item:last-child .ant-menu-title-content {
    justify-content: center;
  }

  .logo-btn {
    width: 24px;
    height: 24px;
  }
  .last-child {
    .container-term {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (min-height: 600px) and (max-height: 768px) {
        margin-bottom: 2.5 * $basic-space;
      }
      @media screen and (min-height: 768px) and (max-height: 1300px) {
        margin-bottom: 6 * $basic-space;
      }
    }
    .term-title {
      font-family: Noto Sans CJK JP;
      font-size: $font-size-normal;
      line-height: 1.3;
      color: $primary-default-color;
    }
    .term-user {
      cursor: pointer;
      font-family: Noto Sans CJK JP;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-normal;
      line-height: 1.3;
      color: $primary-default-color;
      margin-bottom: $basic-space;

      @media screen and (min-height: 700px) {
        margin-bottom: 4 * $basic-space;
      }
      @media screen and (max-height: 550px) {
        margin-bottom: 0;
      }
    }
  }

  .ant-scroll-number-only-unit {
    color: #404040;
  }
  .contentimage {
    width: 100%;
    align-items: flex-start;
  }
  .sider_menu {
    height: 100%;
    width: 100%;
    background: linear-gradient(176.59deg, #3eb7e9 0%, #23a8e1 100%) !important;
    border-radius: 2 * $basic-space;
    max-width: 166px;
    display: flex;
    flex-direction: column;
    .chat {
      position: relative;
    }
    .ant-menu-item-active,
    .ant-menu-item-selected {
      background-color: #23a8e1 !important;
    }
    .menu-active {
      background-color: #62c4ed !important;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 13% !important;
      margin: 0 !important;
      width: 100%;
      padding: 2.5 * $basic-space 3 * $basic-space !important;

      border-bottom: 0.25 * $basic-space solid #53bce9;
    }
    li:last-child {
      height: 22% !important;
      border-bottom: none !important;
      cursor: unset;
    }
    li:last-child:hover {
      border-radius: 0 0 2 * $basic-space 2 * $basic-space;
      background: #3aabe3;
    }

    li:hover {
      color: $primary-default-color !important;
      background: #63c4ed;
    }

    li:focus {
      color: $primary-default-color !important;
    }
    li span {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    li::after {
      border: none;
    }
    li:first-child() {
      border-radius: 2 * $basic-space;
    }
    li:nth-child(5) {
      .ant-menu-title-content {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
    li:first-child():hover {
      border-radius: 2 * $basic-space 2 * $basic-space 0 0;
    }
    li:focus {
      background: #62c4ed;
    }
    .ant-badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-default-color !important;
      .ant-badge-count {
        right: 0.5 * $basic-space !important;
        margin-top: 2 * $basic-space !important;
      }
      .ant-scroll-number-only-unit {
        color: $primary-default-color !important;
      }
    }
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: center;
      font-family: $font-family;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-large;
      line-height: 1.6;
      color: $primary-default-color;
    }
    img {
      padding-bottom: 1.75 * $basic-space;
    }
  }
  .title {
    color: $primary-color-title;
    font-size: $font-size-large;
    line-height: 1.6;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 1.5 * $basic-space;
  }
  .btn {
    width: 100%;
    height: 40px;
    padding: 2.5 * $basic-space 8.75 * $basic-space;
    color: $primary-color-title;
    border: 0.375 * $basic-space solid $primary-default-color;
    box-sizing: border-box;
    border-radius: $basic-space;
    font-size: $font-size-large;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family;
    a {
      padding-top: 1.25 * $basic-space;
    }
  }
}

.modal-preview {
  top: 48px !important;
  .ant-modal-content {
    border-radius: 2 * $basic-space;
    height: 85vh;
    display: flex;
    flex-direction: column;
    min-height: 768px;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        min-width: 1024px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1025px) {
      max-width: 100%;
      min-width: initial;
    }
  }
  .ant-modal-header {
    border: none;
    border-radius: 2 * $basic-space;
    .ant-modal-title {
      font-family: Noto Sans CJK JP;
      font-weight: bold;
      font-size: $font-size-x-large;
      line-height: 1.5;
      color: #404040;
    }
  }
  .ant-modal-body {
    border: none;
    overflow: auto;
    max-height: 100%;
    min-height: 600px;
    justify-content: unset;
    text-align: unset;
    .preview {
      overflow-y: scroll;
      max-height: 100%;
      & {
        ::-webkit-scrollbar {
          width: 1.5 * $basic-space;
          height: 1.5 * $basic-space;
        }
        ::-webkit-scrollbar-track {
          border-radius: 2.5 * $basic-space;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 2.5 * $basic-space;
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-right: 40px;
    background: #ffffff;
  }
  .btn_closepre {
    background: $primary-default-color;
    border: 0.25 * $basic-space solid #21c0f6;
    box-sizing: border-box;
    border-radius: $basic-space;

    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-big;
    line-height: 1.2;
    color: #21c0f6;
  }
}
